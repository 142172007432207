import axios, { AxiosInstance } from "axios";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const API_BASE_URL = "https://maps.googleapis.com/maps";

export interface Location {
  lat: number;
  lng: number;
}

export interface GeometryRow {
  location: Location;
}

export interface GeocodingRow {
  geometry: GeometryRow;
}

export interface GeocodingResponse {
  results: GeocodingRow[];
}

export class GoogleAPI {
  private axiosInstance: AxiosInstance;
  private key: string;

  constructor(
    baseURL: string | undefined = API_BASE_URL,
    key: string | undefined = GOOGLE_API_KEY
  ) {
    this.axiosInstance = axios.create({
      baseURL,
    });
    if (key) {
      this.key = key;
    } else {
      this.key = "";
    }
  }

  async getGetlocation(address: string | undefined, city: string | undefined) {
    return this.axiosInstance.get<GeocodingResponse>("/api/geocode/json", {
      params: {
        address: `${address} ${city}`,
        key: this.key,
      },
    });
  }
}

export const googleApi = new GoogleAPI();
