import { useToast } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useState } from "react";

import { apiService } from "../../Services/ApiService";
import { SubscriptionData } from "../../Types/SubscriptionData";
import SubscriptionTable from "./SubscriptionTable";

interface MySubscriptionsProps {}

const MySubscriptions: FC<MySubscriptionsProps> = () => {
  const toast = useToast();

  const [subscriptions, setSubcriptions] = useState([]);

  const fetchMySubcriptions = useCallback(() => {
    apiService
      .getMySubscriptions()
      .then((response) => {
        const filteredActive = response.data.filter(
          (s: SubscriptionData) => s.status === "active"
        );

        setSubcriptions(filteredActive);
      })
      .catch(() => {
        toast({
          title: "Error getting subscriptions.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [toast]);

  useEffect(() => {
    fetchMySubcriptions();
  }, [fetchMySubcriptions]);

  const deleteSubscription = async (subId: string) => {
    await apiService
      .deleteSubscription(subId)
      .then(() => {
        fetchMySubcriptions();
      })
      .catch(() => {
        toast({
          title: "Error deleting subscriptions.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <SubscriptionTable
      heading="My Subscriptions"
      subscriptions={subscriptions}
      deleteCB={deleteSubscription}
    />
  );
};

export default MySubscriptions;
