import React, { FC, FormEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ProfileCard from "../ProfileCard/ProfileCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInputField from "../CustomInputField/CustomInputField";
import { apiService } from "../../Services/ApiService";
import { ProfileData } from "../../Types/ProfileData";
import { logger } from "../../Logger";
import { AxiosError } from "axios";
import { getDateFormat } from "../../utils/DateTimeFormat";

interface ProfileProps {}

const Profile: FC<ProfileProps> = () => {
  const toast = useToast();
  const [connectAccountId, setConnectAccountId] = useState();
  const [profile, setProfile] = useState<ProfileData>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locale] = useState<string>(navigator.language);

  useEffect(() => {
    apiService
      .getProfile()
      .then((response) => {
        setProfile(response.data);
      })
      .catch((reason: AxiosError) => {
        logger.log(`Profile get error: ${reason}`);
      });
    apiService
      .getConnectAccountId()
      .then((response) => {
        setConnectAccountId(response.data);
      })
      .catch(() => {});
  }, []);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.includes("advance.")) {
      const addressField = name.split(".")[1];
      setProfile({
        ...profile,
        advance: {
          ...profile?.advance,
          [addressField]: value,
        },
      });
    } else {
      setProfile({
        ...profile,
        [name]: value,
      });
    }
  };

  const onSubmitUpdate = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    apiService
      .updateProfile(profile)
      .then((response) => {
        toast({
          title: "Profile update successful.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error: AxiosError) => {
        toast({
          title: "Profile update error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const connectAccountButtonClick = () => {
    setIsSubmitting(true);
    apiService
      .createConenctAccount()
      .then((response) => {
        window.location.href = response.data;
      })
      .catch((error: AxiosError) => {
        toast({
          title: "Profile update error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
      <Box>
        <ProfileCard
          text={"Hi, Patrik Kokol!"}
          email={"patrik.kokol@gmail.com"}
          role={"Admin"}
          isUserProfileCard={true}
        />
      </Box>
      <Box mt="8" bg="white" h="100%" p="4">
        <form onSubmit={onSubmitUpdate}>
          <Tabs bg="white" isFitted>
            <TabList mb="1em" p="4" justifyContent="space-between">
              <Tab>Details</Tab>
              <Tab>Advance config</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex flexDirection="row" p="4" justifyContent="space-around">
                  <CustomInputField
                    id={"name"}
                    label={"NAME"}
                    input={profile?.name}
                    onChange={handleFormChange}
                  />
                  <CustomInputField
                    id={"surname"}
                    label={"SURNAME"}
                    input={profile?.surname}
                    onChange={handleFormChange}
                  />
                </Flex>
                <Flex p="4">
                  <FormControl>
                    <FormLabel color="#868686" fontWeight="normal">
                      BIRTH DATE
                    </FormLabel>
                    <DatePicker
                      yearDropdownItemNumber={100}
                      showYearDropdown
                      scrollableYearDropdown
                      showIcon
                      selected={
                        profile?.birthday === undefined
                          ? new Date()
                          : new Date(profile?.birthday)
                      }
                      onChange={(date) => {
                        setProfile({
                          ...profile,
                          birthday: date?.toISOString(),
                        });
                      }}
                      dateFormat={getDateFormat(locale)}
                    />
                  </FormControl>
                </Flex>
                <Flex p="4">
                  <CustomInputField
                    id={"address"}
                    label={"ADDRESS"}
                    input={profile?.address}
                    onChange={handleFormChange}
                  />
                  <CustomInputField
                    id={"city"}
                    label={"CITY"}
                    input={profile?.city}
                    onChange={handleFormChange}
                  />
                </Flex>
                <Flex p="4">
                  <CustomInputField
                    id={"zip"}
                    label={"ZIP"}
                    input={profile?.zip}
                    onChange={handleFormChange}
                  />
                  <CustomInputField
                    id={"country"}
                    label={"COUNTRY"}
                    input={profile?.country}
                    onChange={handleFormChange}
                  />
                </Flex>
                <Flex p="4">
                  <CustomInputField
                    type="email"
                    id={"email"}
                    label={"EMAIL ADDRESS"}
                    input={profile?.email}
                    onChange={handleFormChange}
                  />
                  <CustomInputField
                    id={"phone"}
                    label={"PHONE"}
                    input={profile?.phone}
                    onChange={handleFormChange}
                  />
                </Flex>
                <Flex p="4">
                  <CustomInputField
                    id={"connectAccountId"}
                    label={"Connect account ID"}
                    input={connectAccountId}
                    isReadOnly={true}
                  />
                  <Button
                    width="1xs"
                    mt="4"
                    bg="success.50"
                    _hover={{ bg: "success.100" }}
                    color="white"
                    onClick={connectAccountButtonClick}
                    isLoading={isSubmitting}
                  >
                    {connectAccountId === undefined ? "Create" : "Refresh"}
                  </Button>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex flexDirection="row" p="4" justifyContent="space-around">
                  <CustomInputField
                    id={"advance.smtpHost"}
                    label={"SMTP host"}
                    input={profile?.advance?.smtpHost}
                    onChange={handleFormChange}
                  />
                  <CustomInputField
                    id={"advance.smtpPort"}
                    label={"SMTP port"}
                    input={profile?.advance?.smtpPort}
                    onChange={handleFormChange}
                  />
                </Flex>
                <Flex flexDirection="row" p="4" justifyContent="space-around">
                  <CustomInputField
                    id={"advance.smtpUsername"}
                    label={"SMTP username"}
                    input={profile?.advance?.smtpUsername}
                    onChange={handleFormChange}
                  />
                  <CustomInputField
                    id={"advance.smtpPassword"}
                    label={"SMTP password"}
                    input={profile?.advance?.smtpPassword}
                    onChange={handleFormChange}
                  />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Box pl="4">
            <Button
              width="1xs"
              mt="4"
              bg="success.50"
              _hover={{ bg: "success.100" }}
              color="white"
              type="submit"
              isLoading={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </form>
      </Box>
    </Flex>
  );
};

export default Profile;
