import React, { FC, useEffect, useRef, useState } from "react";
import { Avatar, Box, Flex, Input, useToast } from "@chakra-ui/react";

import { apiService } from "../../Services/ApiService";

interface ProfilePicProps {
  collabsed: boolean;
}

const SidebarProfilePic: FC<ProfilePicProps> = ({ collabsed }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const fetchProfilePic = async () => {
    apiService
      .getProfileImage()
      .then((response) => {
        const imageUrl = URL.createObjectURL(response.data);
        setAvatarUrl(imageUrl);
      })
      .catch((reason) => reason);
  };

  useEffect(() => {
    fetchProfilePic();
  }, []);

  useEffect(() => {
    if (!selectedFile) {
      setUploadStatus("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setUploadStatus("File uploaded successfully");

      apiService
        .uploadProfileImage(formData)
        .then((response) => {
          toast({
            title: "Profile image upload successfully.",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          fetchProfilePic();
        })
        .catch((reason) => {
          toast({
            title: "Profile image upload error.",
            description: "",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      setUploadStatus("Failed to upload file");
    }

    fetchProfilePic();
  }, [selectedFile, toast]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Box my="6">
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <Avatar
          size={collabsed ? "sm" : "xl"}
          name="Gym logo"
          src={avatarUrl}
          onClick={handleAvatarClick}
        />
      </Box>
    </Flex>
  );
};

export default SidebarProfilePic;
