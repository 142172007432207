import { FC, FormEvent, useEffect, useState } from "react";
import { logger } from "../../Logger";
import { apiService } from "../../Services/ApiService";
import { SubsidiaryData } from "./SubsidiaryData";
import { googleApi } from "../../Services/GoogleAPI";
import { Button, Flex, useToast } from "@chakra-ui/react";
import CustomInputField from "../CustomInputField/CustomInputField";

export interface OnSubmitSuccessFn {
  (close: boolean): void;
}

export interface SubsidiaryAddFormPropr {
  onSubmitSuccess?: OnSubmitSuccessFn;
}

export const SubsidaryAdd: FC<SubsidiaryAddFormPropr> = ({
  onSubmitSuccess,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subsidiaryData, setSubsidiaryData] = useState<SubsidiaryData>({
    location: {
      latitude: 0,
      longitude: 0,
    },
  });
  const toast = useToast();

  useEffect(() => {
    logger.log(`Change: ${JSON.stringify(subsidiaryData)}`);
  }, [subsidiaryData]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.includes("location.")) {
      const localtionField = name.split(".")[1];
      setSubsidiaryData({
        ...subsidiaryData,
        location: {
          ...subsidiaryData?.location,
          [localtionField]: value,
        },
      });
    } else {
      setSubsidiaryData({
        ...subsidiaryData,
        [name]: value,
      });
    }
  };

  const createSubsidiary = (data: SubsidiaryData) => {
    logger.log(`SubsidiaryData before submit: ${JSON.stringify(data)}`);
    apiService
      .createSubsidiary(data)
      .then((response) => {
        toast({
          title: "Subsidiary add successful.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        if (onSubmitSuccess) {
          onSubmitSuccess(true);
        }
      })
      .catch((reason) => {
        toast({
          title: "Subsidiary add error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await googleApi.getGetlocation(
        subsidiaryData?.address,
        subsidiaryData?.city
      );
      const { lng, lat } = response.data.results[0].geometry.location;
      logger.log(`Lat: ${lat} Long: ${lng}`);
      const data = {
        ...subsidiaryData,
        location: {
          longitude: lng,
          latitude: lat,
        },
      };
      createSubsidiary(data);
    } catch {
      const data = {
        ...subsidiaryData,
        location: {
          longitude: 0,
          latitude: 0,
        },
      };
      createSubsidiary(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column">
        <Flex flexDirection="row" p="4" justifyContent="space-around">
          <CustomInputField
            id={"title"}
            label={"TITLE"}
            input={subsidiaryData?.title}
            onChange={handleFormChange}
          />
          <CustomInputField
            id={"address"}
            label={"ADDRESS"}
            input={subsidiaryData?.address}
            onChange={handleFormChange}
          />
        </Flex>
        <Flex flexDirection="row" p="4" justifyContent="space-around">
          <CustomInputField
            id={"city"}
            label={"CITY"}
            input={subsidiaryData?.city}
            onChange={handleFormChange}
          />
          <CustomInputField
            id={"zip"}
            label={"ZIP"}
            type="number"
            input={subsidiaryData?.zip}
            onChange={handleFormChange}
          />
        </Flex>
        <Flex flexDirection="row" p="4" justifyContent="space-around">
          <CustomInputField
            id={"country"}
            label={"COUNTRY"}
            input={subsidiaryData?.country}
            onChange={handleFormChange}
          />
        </Flex>
        <Flex>
          <Button
            width="1xs"
            mt="4"
            bg="success.50"
            _hover={{ bg: "success.100" }}
            color="white"
            type="submit"
            isLoading={isSubmitting}
          >
            Add
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
