import { useToast } from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useState } from "react";

import { apiService } from "../../Services/ApiService";
import { SubscriptionData } from "../../Types/SubscriptionData";
import SubscriptionTable from "./SubscriptionTable";

interface SubscriptionsByUserProps {
  id: Number;
}

const SubscriptionsByUser: FC<SubscriptionsByUserProps> = ({ id }) => {
  const toast = useToast();

  const [subscriptions, setSubcriptions] = useState([]);

  const fetchSubcriptionsForUser = useCallback(() => {
    apiService
      .getSubscriptionsByUserId(id)
      .then((response) => {
        const filteredActive = response.data.filter(
          (s: SubscriptionData) => s.status === "active"
        );

        setSubcriptions(filteredActive);
      })
      .catch(() => {
        toast({
          title: "Error getting subscriptions.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [toast, id]);

  useEffect(() => {
    fetchSubcriptionsForUser();
  }, [fetchSubcriptionsForUser]);

  const deleteSubscription = async (subId: string) => {
    await apiService
      .deleteSubscription(subId)
      .then(() => {
        fetchSubcriptionsForUser();
      })
      .catch(() => {
        toast({
          title: "Error deleting subscriptions.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <SubscriptionTable
      subscriptions={subscriptions}
      deleteCB={deleteSubscription}
    />
  );
};

export default SubscriptionsByUser;
