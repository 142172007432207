import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthConext";
import { logger } from "../Logger";

// Define the type for the ProtectedRoute props
interface ProtectedRouteProps {
  roles: string[];
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles, children }) => {
  const { authToken, userRole } = useAuth();

  if (authToken === null) {
    return <Navigate to="/login" />;
  }

  if (!userRole || !roles.some((role) => userRole.includes(role))) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
