import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  Stack,
  Text,
  Flex,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useState } from "react";
import { ProgramData } from "../../Types/Program";
import { apiService } from "../../Services/ApiService";
import { useParams } from "react-router-dom";

interface CheckoutViewProps {}

export const CheckoutView: FC<CheckoutViewProps> = () => {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [program, setProgram] = useState<ProgramData>({
    isSubscription: false,
    amount: 0.0,
  });

  const fetchProgramDetails = useCallback(() => {
    if (id !== undefined) {
      apiService
        .getProgramDetailsById(Number(id))
        .then((response) => {
          setProgram(response.data);
        })
        .catch(() => {});
    }
  }, [id]);

  useEffect(() => {
    fetchProgramDetails();
    onOpen();
  }, [onOpen, fetchProgramDetails]);

  const closeModal = () => {
    onClose();
    window.location.href = "/";
  };

  const checkoutPayment = () => {
    setIsSubmitting(true);
    apiService
      .checkoutProgram(Number(id))
      .then((response) => {
        window.location.href = response.data;
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold">Order summary</ModalHeader>
        <ModalBody paddingX={8}>
          <Stack>
            <Text color="#A3AED0">Program name:</Text>
            <Text fontWeight="bold">{program.title}</Text>
            {program.isSubscription ? (
              <Text fontWeight="bold">
                {program.amount?.toFixed(2)} {program.currency} / month
              </Text>
            ) : (
              <Text fontWeight="bold">
                {program.amount?.toFixed(2)} {program.currency}
              </Text>
            )}
            <Stack>
              <Flex
                width="100%"
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Text color="#A3AED0">Type:</Text>
                {program.isSubscription ? (
                  <Text fontWeight="bold">Monthly</Text>
                ) : (
                  <Text fontWeight="bold">Class</Text>
                )}
              </Flex>
              <Flex
                width="100%"
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Text color="#A3AED0">Sub total:</Text>
                <Text fontWeight="bold">
                  {program.amount?.toFixed(2)} {program.currency}
                </Text>
              </Flex>
              <Flex
                width="100%"
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Text color="#A3AED0">Tax:</Text>
                <Text fontWeight="bold">0.00 {program.currency}</Text>
              </Flex>
              <Flex
                width="100%"
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Text fontWeight="bold">Total:</Text>
                <Text fontWeight="bold">
                  {program.amount?.toFixed(2)} {program.currency}
                </Text>
              </Flex>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalCloseButton />
        <ModalFooter paddingX={24}>
          <Button
            type="submit"
            width="full"
            mt="4"
            bg="brand.50"
            _hover={{ bg: "brand.100" }}
            color="white"
            isLoading={isSubmitting}
            onClick={checkoutPayment}
          >
            Pay
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
