import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Input,
  useDisclosure,
  Box,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { SubsidiaryData } from "../SubsidaryList/SubsidiaryData";
import { apiService } from "../../Services/ApiService";
import { logger } from "../../Logger";
import { Invitation } from "../../Types/Invitation";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

const ImportModal: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState<File | null>(null);

  const [subsidiaryId, setSubsidiaryId] = useState<number | null>(null);
  const [subsidiaries, setSubsidiaries] = useState<SubsidiaryData[]>([]);

  const toast = useToast();

  useEffect(() => {
    apiService
      .getAllSubsidiaries()
      .then((response) => {
        setSubsidiaries(response.data);
      })
      .catch(() => {});
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSubsidiaryId(Number(event.target.value));
  };

  const onCloseBtnClick = () => {
    onClose();
    setFile(null);
    setSubsidiaryId(null);
  };

  const fileReader = new FileReader();

  const csvFileToArray = (content: string) => {
    const csvHeader = content.slice(0, content.indexOf("\n")).split(",");
    const csvRows = content.slice(content.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((row) => {
      const values = row.split(",");
      const obj: { [key: string]: string } = {};
      csvHeader.forEach((header, index) => {
        obj[header.trim().toLowerCase()] = values[index]?.trim();
      });
      return obj;
    });

    return array;
  };

  const onImportClick = () => {
    if (file) {
      fileReader.onload = (e) => {
        const content = fileReader.result;
        if (typeof content === "string") {
          const csvContent = csvFileToArray(content);
          const invitations: Invitation[] = csvContent.map((row) => {
            const [day, month, year] = row.birthday.split(".").map(Number);
            const birthday = new Date(year, month - 1, day);

            return {
              email: row.email,
              name: row.name,
              surname: row.surname,
              birthday: birthday.toISOString(),
              address: row.address,
              city: row.city,
              zip: row.zip,
              phone: row.phone,
              country: row.country,
            };
          });

          apiService
            .inviteNewMember(invitations, subsidiaryId as number)
            .then((response) => {
              logger.log(`Invited users: ${JSON.stringify(response.data)}`);
              toast({
                title: "Successfuly imported users.",
                description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            })
            .catch((reason) => {
              toast({
                title: "Error importing users.",
                description: "",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            });
        }
      };
      fileReader.readAsText(file);
    }
    onCloseBtnClick();
  };

  return (
    <>
      <Button
        width="1xs"
        mt="4"
        bg="success.50"
        _hover={{ bg: "success.100" }}
        color="white"
        onClick={onOpen}
      >
        Import from CSV
      </Button>

      <Modal isOpen={isOpen} onClose={onCloseBtnClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Import users from CSV File
            <Tooltip
              label={
                <Box>
                  <Text fontWeight="bold">CSV Format:</Text>
                  <Text as="pre" whiteSpace="pre-wrap">
                    email,name,surname,birthday,address,city,zip,phone,country
                    {"\n"}
                    example@example.com,John,Doe,10.1.2002,123 Main
                    St,Anytown,12345,555-555-5555,USA
                  </Text>
                </Box>
              }
              aria-label="CSV Format Tooltip"
              hasArrow
            >
              <QuestionOutlineIcon ml="2" />
            </Tooltip>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select gym"
              bg="white"
              border={"0"}
              boxShadow={"xl"}
              required={true}
              onChange={handleSelectChange}
            >
              {subsidiaries.map((s) => (
                <option value={s.id}>{s.title}</option>
              ))}
            </Select>
            <Input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              mt={4}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mt="4" mr={3} onClick={onCloseBtnClick}>
              Close
            </Button>
            <Button
              isDisabled={!file || !subsidiaryId}
              width="1xs"
              mt="4"
              bg="success.50"
              _hover={{ bg: "success.100" }}
              onClick={onImportClick}
              color="white"
            >
              Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportModal;
