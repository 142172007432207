import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SendIcon } from "../CustomSvgIcons/CustomSvgIcons";

interface EmailFormProps {}

const EmailForm: FC<EmailFormProps> = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  return (
    <>
      <Box>
        <Flex>
          <FormControl isRequired>
            <FormLabel>Subject:</FormLabel>
            <Input placeholder="Message title" />
            <FormLabel>Message:</FormLabel>
            <Box borderWidth="1px" p="2" mb="4">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </Box>
            <Flex justifyContent="right">
              <Button
                bg="brand.50"
                width="2xs"
                _hover={{ bg: "brand.100" }}
                color="white"
                leftIcon={<SendIcon height={34} width={34} color={"white"} />}
              >
                Send
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      </Box>
    </>
  );
};

export default EmailForm;
