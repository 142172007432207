import React, { FC, useCallback, useEffect, useState } from "react";
import { OnPageChangeEvent } from "../../Types/OnPageChangeEvent";
import { Badge, Box, Flex, useToast } from "@chakra-ui/react";
import { DataTable } from "../DataTable/DataTable";
import ReactPaginate from "react-paginate";
import { createColumnHelper } from "@tanstack/react-table";
import { apiService } from "../../Services/ApiService";
import { PaymentData } from "../../Types/PaymentData";
import { formatDateTime } from "../../utils/DateTimeFormat";

interface PaymentTableByUserProps {
  id: number;
}

export const PaymentTableByUser: FC<PaymentTableByUserProps> = ({ id }) => {
  const toast = useToast();
  const [payments, setPayments] = useState<PaymentData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(10); // Number of items per page
  const [locale] = useState<string>(navigator.language);
  const columnHelper = createColumnHelper<PaymentData>();
  const columns = [
    columnHelper.accessor("id", {
      cell: (info) => info.getValue(),
      header: "ID",
    }),
    columnHelper.accessor("programName", {
      cell: (info) => info.getValue(),
      header: "Title",
    }),
    columnHelper.accessor("amount", {
      cell: (info) => (
        <>
          <Badge colorScheme="green">{info.getValue()} EUR</Badge>
        </>
      ),
      header: "Amount",
    }),
    columnHelper.accessor("status", {
      cell: (info) => info.getValue(),
      header: "Status",
    }),
    columnHelper.accessor("paymentDate", {
      cell: (info) => {
        const val = info.getValue();
        if (val !== undefined) {
          const date = new Date(val);
          return formatDateTime(locale, date);
        } else {
          return "";
        }
      },
      header: "Payment Date",
    }),
  ];

  const fetchPaymentsForUser = useCallback(() => {
    apiService
      .getPaymentsForUserById(id, { page: currentPage, pageSize: pageSize })
      .then((response) => {
        setPayments(response.data.content);
        setTotalPages(response.data.totalPages);
      })
      .catch(() => {
        toast({
          title: "Fetching payments for user error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [currentPage, pageSize, toast, id]);

  useEffect(() => {
    fetchPaymentsForUser();
  }, [fetchPaymentsForUser]);

  const handlePageChange = (selected: OnPageChangeEvent) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Flex direction="column">
      <Box>
        <DataTable columns={columns} data={payments} />
      </Box>
      <Box>
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </Box>
    </Flex>
  );
};
