import React, { FC } from "react";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";

interface StatCardProps {
  imgSrc: any;
  text: string;
  value: string;
}

const StatCard: FC<StatCardProps> = ({ imgSrc, text, value }) => (
  <Flex p="4">
    <Box bg="white" p="4" borderRadius="lg" boxShadow="md">
      <Flex justifyContent="space-between" alignItems="center" px="8">
        <Avatar size="md" name="view_avatar" src={imgSrc} />
        <Box pl="6">
          <Flex direction="column">
            <Box>
              <Text
                fontFamily="Poppins"
                lineHeight="1.4"
                fontWeight="semibold"
                fontSize="20px"
                color={"gray.50"}
              >
                {text}
              </Text>
            </Box>
            <Box pt="1">
              <Text
                fontFamily="Poppins"
                lineHeight="1.4"
                fontWeight="bold"
                fontSize="24px"
                color={"gray.500"}
              >
                {value}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  </Flex>
);

export default StatCard;
