import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import DeleteIconAndModal from "../DeleteIconAndModal/DeleteIconAndModal";
import EmailDrawer from "../EmailDrawer/EmailDrawer";

const ActionCell = ({ id = 0, route = "/" }) => {
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        bg="transparent"
        aria-label="Edit"
        icon={<EditIcon />}
        onClick={(event) => navigate(route)}
      />

      <DeleteIconAndModal />

      <EmailDrawer />
    </>
  );
};

export default ActionCell;
