import React, { FC, useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import ActionCell from "../ActionCell/ActionCell";
import { DataTable } from "../DataTable/DataTable";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { SubsidaryAdd } from "./SubsidiaryAdd";
import { logger } from "../../Logger";
import { apiService } from "../../Services/ApiService";
import { SubsidiaryData } from "./SubsidiaryData";
import { OnPageChangeEvent } from "../../Types/OnPageChangeEvent";

interface SubsidaryListProps {}

const columnHelper = createColumnHelper<SubsidiaryData>();
const columns = [
  columnHelper.accessor("title", {
    cell: (info) => info.getValue(),
    header: "Title",
  }),
  columnHelper.accessor("address", {
    cell: (info) => info.getValue(),
    header: "Address",
  }),
  columnHelper.accessor("city", {
    cell: (info) => info.getValue(),
    header: "City",
  }),
  columnHelper.accessor("location", {
    cell: (info) => <FontAwesomeIcon icon={faMapLocationDot} />,
    header: "Location",
  }),
  columnHelper.accessor("id", {
    cell: (info) => {
      return (
        <>
          <ActionCell
            id={info.getValue()}
            route={`/subsidaries/${info.getValue()}`}
          />
        </>
      );
    },
    header: "Actions",
  }),
];

const SubsidiaryList: FC<SubsidaryListProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [data, setData] = useState<SubsidiaryData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(10);
  const [pageSize] = useState(10); // Number of items per page

  const fetchSubsidiaries = useCallback(() => {
    logger.log(`Current selected page ${currentPage}`);
    apiService
      .getSubsidiaries({ page: currentPage, pageSize: pageSize })
      .then((response) => {
        logger.log(`Response: ${JSON.stringify(response.data.content)}`);
        setData(response.data.content);
        setTotalPages(response.data.totalPages);
      })
      .catch(() => {
        toast({
          title: "Fetching subsidiaries error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [currentPage, pageSize, toast]);

  useEffect(() => {
    fetchSubsidiaries();
  }, [fetchSubsidiaries]);

  const handleClick = () => {
    onOpen();
  };

  const onSubmitSuccess = (success: boolean) => {
    if (success) {
      onClose();
      fetchSubsidiaries();
    }
  };

  const handlePageChange = (selected: OnPageChangeEvent) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
      <Box>
        <Heading as="h1" size="2xl">
          Subsidiaries
        </Heading>
      </Box>
      <Box mb="2" textAlign="right">
        <Button
          width="1xs"
          mt="4"
          bg="success.50"
          _hover={{ bg: "success.100" }}
          color="white"
          type="submit"
          onClick={handleClick}
        >
          Add subsidiary
        </Button>
      </Box>
      <Box>
        <DataTable columns={columns} data={data} />
      </Box>
      <Box>
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </Box>
      <Drawer onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Add Subsidiary`}</DrawerHeader>
          <DrawerBody>
            <SubsidaryAdd onSubmitSuccess={onSubmitSuccess} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default SubsidiaryList;
