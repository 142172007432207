import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Input,
  useDisclosure,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ProgramData } from "../../Types/Program";
import { logger } from "../../Logger";
import { apiService } from "../../Services/ApiService";

interface CouponProprs {
  program: ProgramData;
}

export interface CouponData {
  id?: number;
  promotionCode?: string;
  percentOff?: number;
  duration?: string;
  durationInMonths?: number;
}

const CouponModal: React.FC<CouponProprs> = ({ program }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [percentOff, setPercentOff] = useState<number>(0);
  const [durationInMonths, setDurationInMonths] = useState<number>(1);
  const [durationType, setDurationType] = useState<string>("once");

  const [isGenerating, setIsGenerating] = useState(false);

  const [couponData, setCouponData] = useState<CouponData | undefined>(
    undefined
  );

  const onCloseBtnClick = () => {
    onClose();
    setPercentOff(0);
    setDurationInMonths(1);
    setDurationType("once");
    setCouponData(undefined);
  };

  const onGenerateCoupon = () => {
    const couponData: CouponData = {
      percentOff,
      duration: durationType,
      durationInMonths,
    };
    setIsGenerating(true);

    apiService
      .generateCoupon(program.id, couponData)
      .then((response) => {
        if (response.status === 200) {
          logger.log(
            `Coupon ${response.data.promotionCode} generated successfully`
          );
          setCouponData(response.data);
        }
      })
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  return (
    <>
      <Button
        isDisabled={program.stripeId === undefined}
        width="1xs"
        mt="4"
        bg="success.50"
        _hover={{ bg: "success.100" }}
        color="white"
        onClick={onOpen}
      >
        Generate coupon
      </Button>

      <Modal isOpen={isOpen} onClose={onCloseBtnClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate coupon</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Percentage off</FormLabel>
              <InputGroup>
                <Input
                  placeholder="Percentage off"
                  type="number"
                  value={percentOff ?? ""}
                  onChange={(e) => setPercentOff(Number(e.target.value))}
                />
                <InputRightAddon color={"white"} bg={"gray"}>
                  %
                </InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Duration in months</FormLabel>
              <InputGroup>
                <Input
                  placeholder="Duration in months"
                  type="number"
                  value={durationInMonths ?? ""}
                  onChange={(e) => setDurationInMonths(Number(e.target.value))}
                />
                <InputRightAddon color={"white"} bg={"gray"}>
                  months
                </InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Duration type</FormLabel>
              <Select
                placeholder="Select duration type"
                value={durationType}
                onChange={(e) => setDurationType(e.target.value)}
              >
                <option value="ONCE">Once</option>
                <option value="FOREVER">Forever</option>
                <option value="REPEATING">Repeating</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {couponData && (
                <Text>
                  Coupon generated: <b>{couponData.promotionCode}</b>
                </Text>
              )}
              <Button
                width="1xs"
                bg="success.50"
                _hover={{ bg: "success.100" }}
                onClick={onGenerateCoupon}
                isLoading={isGenerating}
                color="white"
              >
                Generate
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CouponModal;
