import React, { FC, useCallback, useEffect, useState } from "react";
import { apiService } from "../../Services/ApiService";
import { Button } from "@chakra-ui/button";
import { SubsidiaryData } from "./SubsidiaryData";
import { Map, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import { logger } from "../../Logger";
import { Flex, Box, Heading } from "@chakra-ui/react";
import { ProgramCard } from "../ProgramsList/ProgramCard";

interface SubsidiariesSeachViewProps {}

interface ProgramCardData {
  id: number;
  title: string;
  type: string;
  amount: number;
  programUrl: string;
  currency: string;
}

export const SubsidiariesSearchView: FC<SubsidiariesSeachViewProps> = () => {
  const [gyms, setGyms] = useState<SubsidiaryData[]>([]);
  const [programDetails, setProgramDetails] = useState<ProgramCardData[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const [gymOffer, setGymOffer] = useState<SubsidiaryData | undefined>();

  const [hoveredGym, setHoveredGym] = useState<number | undefined | null>(null);
  const handleMouseOver = (gymId: number | undefined) => {
    setHoveredGym(gymId);
  };

  const handleMouseOut = () => {
    setHoveredGym(null);
  };

  const markerClick = useCallback((g: SubsidiaryData) => {
    setGymOffer(g);
    if (g.id !== undefined) {
      apiService
        .getSubsidiaryOffer(g.id)
        .then((response) => {
          setProgramDetails(response.data);
        })
        .catch();
    }
  }, []);

  const fetchGyms = useCallback((pageNum: number) => {
    setLoading(true);

    apiService
      .getAllSubsidiariesPaged({ page: pageNum, pageSize: 10 })
      .then((response) => {
        setGyms((prevGyms) => [...prevGyms, ...response.data.content]); // Append new gyms to existing ones
        setTotalPages(response.data.totalPages);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchGyms(page); // Fetch the first page on initial load
  }, [page, fetchGyms]);

  useEffect(() => {
    logger.log(JSON.stringify(gyms));
  }, [gyms]);

  const loadMoreGyms = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Flex direction={"column"} bg="background.50" h={"100%"}>
      <Box height="600px" width="100%">
        <Map
          mapId="GYM_STAR_MAP"
          defaultZoom={9}
          defaultCenter={{ lat: 46.0595151, lng: 14.5035583 }}
        >
          {gyms.map((g: SubsidiaryData) =>
            g.location &&
            g.location.latitude !== undefined &&
            g.location.longitude !== undefined ? (
              <AdvancedMarker
                clickable={true}
                onClick={() => markerClick(g)}
                key={g.id}
                position={{
                  lat: g.location?.latitude,
                  lng: g.location?.longitude,
                }}
              >
                <Pin
                  background={"#F96E0A"}
                  glyphColor={"#000"}
                  borderColor={"#000"}
                />
              </AdvancedMarker>
            ) : (
              <></>
            )
          )}
        </Map>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        {/* Pagination: Load More Button */}
        {page < totalPages && (
          <Button
            bg="brand.50"
            _hover={{ bg: "brand.100" }}
            color="white"
            onClick={loadMoreGyms}
            isLoading={loading}
            mt={4}
          >
            Load More Gyms
          </Button>
        )}
      </Box>
      {gymOffer && (
        <Flex direction={"column"}>
          <Box marginLeft={8} marginTop={8}>
            <Heading as="h1" size="2xl">
              Offer for: {gymOffer?.title}
            </Heading>
          </Box>
          <Box p={8} maxW={"900px"}>
            {programDetails.map((d: ProgramCardData) => (
              <ProgramCard
                itemId={d.id}
                picUrl={d.programUrl}
                title={d.title}
                type={d.type}
                price={d.amount}
                currency={d.currency}
              />
            ))}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
