import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon, RepeatIcon } from "@chakra-ui/icons";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../DataTable/DataTable";
import ActionCell from "../ActionCell/ActionCell";
import { UserData } from "../../Types/UserData";
import { logger } from "../../Logger";
import { Invitation } from "../../Types/Invitation";
import { apiService } from "../../Services/ApiService";
import { OnPageChangeEvent } from "../../Types/OnPageChangeEvent";
import ReactPaginate from "react-paginate";
import { InviteForm } from "../InviteForm/InviteForm";
import ImportModal from "../ImportModal/ImportModal";

interface MemberListProps {}

const columnHelper = createColumnHelper<UserData>();
const columns = [
  columnHelper.accessor("profile.name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  columnHelper.accessor("profile.surname", {
    cell: (info) => info.getValue(),
    header: "Surname",
  }),
  columnHelper.accessor("id", {
    cell: (info) => {
      return (
        <>
          <ActionCell
            id={info.getValue()}
            route={`/members/${info.getValue()}`}
          />
        </>
      );
    },
    header: "Actions",
  }),
];

export const MemberTable: FC<MemberListProps> = () => {
  const toast = useToast();
  const [data, setData] = useState<UserData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(10);
  const [pageSize] = useState(10); // Number of items per page

  const fetchMembers = useCallback(() => {
    logger.log(`Current selected page ${currentPage}`);
    apiService
      .getMembers({ page: currentPage, pageSize: pageSize })
      .then((response) => {
        logger.log(`Response: ${JSON.stringify(response.data.content)}`);
        setData(response.data.content);
        setTotalPages(response.data.totalPages);
      })
      .catch(() => {
        toast({
          title: "Fetching staff error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [currentPage, pageSize, toast]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const handlePageChange = (selected: OnPageChangeEvent) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Box>
      <Box>
        <DataTable columns={columns} data={data} />
      </Box>
      <Box>
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </Box>
    </Box>
  );
};

const MemberList: FC<MemberListProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = () => {
    onOpen();
  };

  const onInvitationSubmit = async (data: Invitation, subsidiaryId: number) => {
    logger.log("Invitation submit called");
    logger.log(
      `Submiting ${JSON.stringify(data)} to subsidiary with id ${subsidiaryId}`
    );

    const invitations: Invitation[] = [];
    invitations.push(data);

    // if Everything is ok close the drawer
    await apiService
      .inviteNewMember(invitations, subsidiaryId)
      .then((response) => {
        onClose();
        toast({
          title: "New member invited.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((reason) => {
        toast({
          title: "Inviting new member error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const bulkImport = async () => {
    setIsSubmitting(true);
    try {
    } catch {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
      <Box mb="6">
        <Heading as="h1" size="2xl">
          Members
        </Heading>
      </Box>
      <Box mb="2" textAlign="center">
        <Center>
          <Box bg="white" p="4" borderRadius="lg" boxShadow="md">
            <HStack spacing="6">
              <Box bg="blackAlpha.50" p="3" borderRadius="100%">
                <CheckCircleIcon color="brand.50" boxSize="6" />
              </Box>
              <Box>
                <VStack alignItems="start">
                  <Heading color="blackAlpha.400" size="md">
                    Active members
                  </Heading>
                  <Heading color="blackAlpha.800" size="md">
                    -
                  </Heading>
                </VStack>
              </Box>
            </HStack>
          </Box>
          <Box w="2"></Box>
          <Box bg="white" p="4" borderRadius="lg" boxShadow="md">
            <HStack spacing="6">
              <Box bg="blackAlpha.50" p="3" borderRadius="100%">
                <CloseIcon color="red" boxSize="6" />
              </Box>
              <Box>
                <VStack alignItems="start">
                  <Heading color="blackAlpha.400" size="md">
                    Inactive members
                  </Heading>
                  <Heading color="blackAlpha.800" size="md">
                    -
                  </Heading>
                </VStack>
              </Box>
            </HStack>
          </Box>
          <Box w="2"></Box>
          <Box bg="white" p="4" borderRadius="lg" boxShadow="md">
            <HStack spacing="6">
              <Box bg="blackAlpha.50" p="3" borderRadius="100%">
                <RepeatIcon color="orange" boxSize="6" />
              </Box>
              <Box>
                <VStack alignItems="start">
                  <Heading color="blackAlpha.400" size="md">
                    Pending members
                  </Heading>
                  <Heading color="blackAlpha.800" size="md">
                    -
                  </Heading>
                </VStack>
              </Box>
            </HStack>
          </Box>
        </Center>
      </Box>
      <Flex width="100%" justifyContent="end" gap={4} mb="2" textAlign="right">
        <Button
          width="1xs"
          mt="4"
          bg="success.50"
          _hover={{ bg: "success.100" }}
          color="white"
          onClick={handleClick}
        >
          Add
        </Button>
        <ImportModal />
      </Flex>
      <Box>
        <MemberTable />
      </Box>
      <Drawer onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Add members`}</DrawerHeader>
          <DrawerBody>
            <InviteForm onSubmitCb={onInvitationSubmit} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default MemberList;
