import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import EmailForm from "../EmailForm/EmailForm";

const EmailDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    onOpen();
  };

  return (
    <>
      <IconButton
        ml="1"
        bg="transparent"
        aria-label="Send Email"
        icon={<EmailIcon />}
        onClick={handleClick}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Email drawer`}</DrawerHeader>
          <DrawerBody>
            <EmailForm />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default EmailDrawer;
