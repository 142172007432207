import { useRouteError } from "react-router-dom";
import React from "react";

const ErrorPage: React.FC = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <i>
        {(error as Error)?.message ||
          (error as { statusText?: string })?.statusText}
      </i>
    </div>
  );
};

export default ErrorPage;
