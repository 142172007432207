import React, { FC, useState } from "react";
import { Badge, Box, Flex, Heading, useToast } from "@chakra-ui/react";
import { DataTable } from "../DataTable/DataTable";
import DeleteIconAndModal from "../DeleteIconAndModal/DeleteIconAndModal";
import { createColumnHelper } from "@tanstack/react-table";
import { SubscriptionData } from "../../Types/SubscriptionData";
import { formatDate } from "../../utils/DateTimeFormat";

interface DeleteFN {
  (subId: string): Promise<void>;
}

interface SubscriptionTableProp {
  subscriptions: SubscriptionData[];
  heading?: string;
  deleteCB: DeleteFN;
}

const SubscriptionTable: FC<SubscriptionTableProp> = ({
  subscriptions,
  heading,
  deleteCB,
}) => {
  const columnHelper = createColumnHelper<SubscriptionData>();
  const [locale] = useState<string>(navigator.language);
  const columns = [
    columnHelper.accessor("programName", {
      cell: (info) => info.getValue(),
      header: "Program name",
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => formatDate(locale, new Date(info.getValue())),
      header: "Created At",
    }),
    columnHelper.accessor("amount", {
      cell: (info) => (
        <>
          <Badge colorScheme="green">{info.getValue()} EUR</Badge>
        </>
      ),
      header: "Amount",
    }),
    columnHelper.accessor("id", {
      cell: (info) => {
        return (
          <>
            <span>{info.getValue()}</span>
            <DeleteIconAndModal
              deleteFn={deleteCB}
              resourceId={info.getValue()}
              headerText="Delete subscriptions"
              dialogBody="Are you sure? You can't undo this action afterwards."
            />
          </>
        );
      },
      header: "Subscription id",
    }),
  ];

  return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
      <Box hidden={heading === undefined}>
        <Heading as="h1" size="2xl">
          {heading}
        </Heading>
      </Box>
      <Box p={heading ? "4" : "0"}>
        <DataTable columns={columns} data={subscriptions} />
      </Box>
    </Flex>
  );
};

export default SubscriptionTable;
