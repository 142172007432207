import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomInputField from "../CustomInputField/CustomInputField";
import { ProgramData } from "../../Types/Program";
import { apiService } from "../../Services/ApiService";
import { DataTable } from "../DataTable/DataTable";
import DeleteIconAndModal from "../DeleteIconAndModal/DeleteIconAndModal";
import { createColumnHelper } from "@tanstack/react-table";
import { UserData } from "../../Types/UserData";
import { logger } from "../../Logger";
import { StaffDetails } from "../../Types/StaffDetails";
import CouponModal from "../CouponModal/CouponModal";

interface ProgramDetailProps {}

const columnHelper = createColumnHelper<UserData>();
const columns = [
  columnHelper.accessor("profile.name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  columnHelper.accessor("profile.surname", {
    cell: (info) => info.getValue(),
    header: "Surname",
  }),
  columnHelper.accessor("profile.phone", {
    cell: (info) => info.getValue(),
    header: "Contact",
  }),
  columnHelper.accessor("id", {
    cell: (info) => {
      return (
        <>
          <DeleteIconAndModal />
        </>
      );
    },
    header: "Actions",
  }),
];

export const ProgramDetail: FC<ProgramDetailProps> = () => {
  const { id } = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    undefined
  );
  const [staff, setStaff] = useState<StaffDetails[]>();

  const [program, setProgram] = useState<ProgramData>({
    isSubscription: false,
  });

  const fetchProgramDetails = useCallback(() => {
    if (id !== undefined) {
      apiService
        .getProgramDetailsById(Number(id))
        .then((response) => {
          setProgram(response.data);
        })
        .catch(() => {});
    }
  }, [id]);

  useEffect(() => {
    fetchProgramDetails();

    apiService
      .getAllStaff()
      .then((response) => {
        setStaff(response.data);
      })
      .catch(() => {
        toast({
          title: "Getting staff list error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [fetchProgramDetails, toast]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data: ProgramData = {
      ...program,
      id: undefined,
    };
    apiService
      .updateProgramById(Number(id), data)
      .then(() => {
        toast({
          title: "Program update successful.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Updating programs error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClick = () => {
    onOpen();
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProgram({
      ...program,
      [name]: value,
    });
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setProgram({
      ...program,
      [name]: checked,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(Number(event.target.value));
  };

  const handleOkClick = async () => {
    // Add teacher to program
    logger.log(`Adding program id ${program.id} to program ${selectedValue}`);
    if (program.id !== undefined && selectedValue !== undefined) {
      apiService
        .addTeacher(selectedValue, program.id)
        .then(() => {
          toast({
            title: "Program added successful.",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          fetchProgramDetails();
          onClose();
        })
        .catch(() => {
          toast({
            title: "Adding program error.",
            description: "",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } else {
      toast({
        title: "Adding program error.",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
      <Box>
        <Heading as="h1" size="2xl">
          Program details
        </Heading>
      </Box>
      <Box>
        <form onSubmit={onSubmit}>
          <Flex flexDirection="column">
            <Flex flexDirection="row" p="4" justifyContent="space-around">
              <CustomInputField
                id={"title"}
                label={"TITLE"}
                input={program?.title}
                onChange={handleFormChange}
                isRequired={true}
              />
              <FormControl id="amount">
                <FormLabel color="#868686" fontWeight="normal">
                  {"AMOUNT"}
                </FormLabel>
                <InputGroup>
                  <Input
                    name="amount"
                    type="number"
                    borderTop="0"
                    borderRight="0"
                    borderLeft="0"
                    borderRadius="0"
                    borderColor="#F3F2F2"
                    _focusVisible={{ outline: "none" }}
                    onChange={handleFormChange}
                    defaultValue={
                      program.amount === undefined ? "" : program.amount
                    }
                    isRequired
                  />
                  <InputRightElement pointerEvents="none">
                    <span>EUR</span>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex p="4">
              <FormControl>
                <FormLabel color="#868686" fontWeight="normal">
                  {"SUBSCRIPTION"}
                </FormLabel>
                <Switch
                  name="isSubscription"
                  size="md"
                  colorScheme="brand"
                  isChecked={program.isSubscription}
                  onChange={handleSwitchChange}
                />
              </FormControl>
            </Flex>
          </Flex>
          <Flex p="4" direction={"column"}>
            <FormControl>
              <Box mb="2" textAlign="right">
                <Button
                  width="1xs"
                  mt="4"
                  bg="success.50"
                  _hover={{ bg: "success.100" }}
                  color="white"
                  onClick={handleClick}
                >
                  Add coach
                </Button>
              </Box>
              <Box>
                <DataTable
                  columns={columns}
                  data={program.trainers !== undefined ? program.trainers : []}
                />
              </Box>
            </FormControl>
          </Flex>
          <Flex gap={4}>
            <Button
              width="1xs"
              mt="4"
              bg="success.50"
              _hover={{ bg: "success.100" }}
              color="white"
              type="submit"
              isLoading={isSubmitting}
            >
              Update program
            </Button>
            <CouponModal program={program} />
          </Flex>
        </form>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#868686" fontWeight="normal">
            Select trainer
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select trainer"
              onChange={handleSelectChange}
              bg="white"
              border={"0"}
              boxShadow={"xl"}
            >
              {staff?.map((s) => (
                <option value={s.id}>
                  {s.profile?.name} {s.profile?.surname}
                </option>
              ))}
            </Select>
          </ModalBody>

          <ModalFooter justifyContent={"center"}>
            <Button
              bg="red.500"
              _hover={{ bg: "red.600" }}
              color="white"
              onClick={onClose}
              mr={"4"}
            >
              Cancel
            </Button>
            <Button
              bg="success.50"
              _hover={{ bg: "success.100" }}
              color="white"
              onClick={handleOkClick}
              isLoading={isSubmitting}
              disabled={!selectedValue}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
