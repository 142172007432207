import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { FC, FormEvent, useCallback, useEffect, useState } from "react";
import ProfileCard from "../ProfileCard/ProfileCard";
import CustomInputField from "../CustomInputField/CustomInputField";
import { apiService } from "../../Services/ApiService";
import { ProfileData } from "../../Types/ProfileData";
import { AxiosError } from "axios";
import { logger } from "../../Logger";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { DataTable } from "../DataTable/DataTable";
import { ProgramData } from "../../Types/Program";
import { createColumnHelper } from "@tanstack/react-table";
import DeleteIconAndModal from "../DeleteIconAndModal/DeleteIconAndModal";
import { StaffDetails } from "../../Types/StaffDetails";
import { getDateFormat } from "../../utils/DateTimeFormat";

interface StaffDetailsProps {}

const columnHelper = createColumnHelper<ProgramData>();
const columns = [
  columnHelper.accessor("title", {
    cell: (info) => info.getValue(),
    header: "Program name",
  }),
  columnHelper.accessor("id", {
    cell: (info) => {
      return (
        <>
          <DeleteIconAndModal />
        </>
      );
    },
    header: "Actions",
  }),
];

export const StaffDetailComponent: FC<StaffDetailsProps> = () => {
  const { id } = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    undefined
  );
  const [allPrograms, setAllPrograms] = useState<ProgramData[]>([]);
  const [locale] = useState<string>(navigator.language);
  const [staffDetails, setStaffDetails] = useState<StaffDetails>({
    teaches: [],
  });
  const [profile, setProfile] = useState<ProfileData>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchStaffDetails = useCallback(() => {
    apiService
      .getStaffById(Number(id))
      .then((response) => {
        setStaffDetails(response.data);
        setProfile(response.data.profile);
      })
      .catch((reason: AxiosError) => {
        logger.log(`Profile get error: ${reason}`);
      });
  }, [id]);

  useEffect(() => {
    if (id !== undefined) {
      fetchStaffDetails();

      apiService
        .getAllPrograms()
        .then((response) => {
          setAllPrograms(response.data);
        })
        .catch(() => {
          toast({
            title: "Get list of programs error.",
            description: "",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }, [fetchStaffDetails, toast, id]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.includes("advance.")) {
      const addressField = name.split(".")[1];
      setProfile({
        ...profile,
        advance: {
          ...profile?.advance,
          [addressField]: value,
        },
      });
    } else {
      setProfile({
        ...profile,
        [name]: value,
      });
    }
  };

  const onSubmitUpdate = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    apiService
      .updateUserById(profile, Number(id))
      .then((response) => {
        toast({
          title: "Update successful.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error: AxiosError) => {
        toast({
          title: "Update error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    setIsSubmitting(false);
  };

  const handleClick = () => {
    onOpen();
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(Number(event.target.value));
  };

  const handleOkClick = async () => {
    // Add teacher to program
    logger.log(
      `Adding staff id ${staffDetails.id} to program ${selectedValue}`
    );
    if (staffDetails.id !== undefined && selectedValue !== undefined) {
      apiService
        .addTeacher(staffDetails.id, selectedValue)
        .then(() => {
          toast({
            title: "Program added successful.",
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          fetchStaffDetails();
          onClose();
        })
        .catch(() => {
          toast({
            title: "Adding program error.",
            description: "",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } else {
      toast({
        title: "Adding program error.",
        description: "",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
      <Box>
        <ProfileCard
          text={`${profile.name} ${profile.surname}`}
          email={`${profile.email}`}
          role={"Trainer"}
          isUserProfileCard={false}
        />
      </Box>
      <Box mt="8" bg="white" h="100%" p="4">
        <form onSubmit={onSubmitUpdate}>
          <Flex flexDirection="row" p="4" justifyContent="space-around">
            <CustomInputField
              id={"name"}
              label={"NAME"}
              input={profile?.name}
              onChange={handleFormChange}
            />
            <CustomInputField
              id={"surname"}
              label={"SURNAME"}
              input={profile?.surname}
              onChange={handleFormChange}
            />
          </Flex>
          <Flex p="4">
            <FormControl>
              <FormLabel color="#868686" fontWeight="normal">
                BIRTH DATE
              </FormLabel>
              <DatePicker
                yearDropdownItemNumber={100}
                showYearDropdown
                scrollableYearDropdown
                showIcon
                selected={
                  profile?.birthday === undefined
                    ? new Date()
                    : new Date(profile?.birthday)
                }
                onChange={(date) => {
                  setProfile({
                    ...profile,
                    birthday: date?.toISOString(),
                  });
                }}
                dateFormat={getDateFormat(locale)}
              />
            </FormControl>
          </Flex>
          <Flex p="4">
            <CustomInputField
              id={"address"}
              label={"ADDRESS"}
              input={profile?.address}
              onChange={handleFormChange}
            />
            <CustomInputField
              id={"city"}
              label={"CITY"}
              input={profile?.city}
              onChange={handleFormChange}
            />
          </Flex>
          <Flex p="4">
            <CustomInputField
              id={"zip"}
              label={"ZIP"}
              input={profile?.zip}
              onChange={handleFormChange}
            />
            <CustomInputField
              id={"country"}
              label={"COUNTRY"}
              input={profile?.country}
              onChange={handleFormChange}
            />
          </Flex>
          <Flex p="4">
            <CustomInputField
              type="email"
              id={"email"}
              label={"EMAIL ADDRESS"}
              input={profile?.email}
              onChange={handleFormChange}
            />
            <CustomInputField
              id={"phone"}
              label={"PHONE"}
              input={profile?.phone}
              onChange={handleFormChange}
            />
          </Flex>
          <Flex p="4" direction={"column"}>
            <FormControl>
              {/* <FormLabel color="#868686" fontWeight="normal">{"TEACHES"}</FormLabel> */}
              <Box mb="2" textAlign="right">
                <Button
                  width="1xs"
                  mt="4"
                  bg="success.50"
                  _hover={{ bg: "success.100" }}
                  color="white"
                  onClick={handleClick}
                >
                  Add program
                </Button>
              </Box>
              <Box>
                <DataTable columns={columns} data={staffDetails?.teaches} />
              </Box>
            </FormControl>
          </Flex>
          <Box pl="4">
            <Button
              width="1xs"
              mt="4"
              bg="success.50"
              _hover={{ bg: "success.100" }}
              color="white"
              type="submit"
              isLoading={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </form>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#868686" fontWeight="normal">
            Select a program
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select program"
              onChange={handleSelectChange}
              bg="white"
              border={"0"}
              boxShadow={"xl"}
            >
              {allPrograms.map((p) => (
                <option value={p.id}>{p.title}</option>
              ))}
            </Select>
          </ModalBody>

          <ModalFooter justifyContent={"center"}>
            <Button
              bg="red.500"
              _hover={{ bg: "red.600" }}
              color="white"
              onClick={onClose}
              mr={"4"}
            >
              Cancel
            </Button>
            <Button
              bg="success.50"
              _hover={{ bg: "success.100" }}
              color="white"
              onClick={handleOkClick}
              isLoading={isSubmitting}
              disabled={!selectedValue}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
