import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { logger } from "../Logger";
import { UserRole } from "../Types/UserRoleEnum";

// Define the shape of the AuthContext
interface AuthContextProps {
  authToken: string | null;
  userRole: string | string[] | null;
  login: (token: string, refreshToken: string) => void;
  logout: () => void;
  hasRole: (role: UserRole) => boolean;
}

// Create AuthContext with default values
const AuthContext = createContext<AuthContextProps>({
  authToken: null,
  userRole: null,
  login: () => {},
  logout: () => {},
  hasRole: (role: UserRole) => {
    return false;
  },
});

// Define the type for the AuthProvider props
interface AuthProviderProps {
  children: ReactNode;
}

interface CustomJWTPayload extends JwtPayload {
  roles?: string[] | string;
}

const getRoleFromToken = (token: string | null): string | string[] | null => {
  if (token === null) return null;
  try {
    const decoded = jwtDecode<CustomJWTPayload>(token);
    if (decoded.roles !== undefined) {
      return decoded.roles;
    }
  } catch (error) {
    logger.log("Invalid token:", error);
  }
  return null;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("token"));
  const [userRole, setUserRole] = useState<string | string[] | null>(
    getRoleFromToken(localStorage.getItem("token"))
  );

  useEffect(() => {
    if (authToken) {
      const role = getRoleFromToken(authToken);
      setUserRole(role);
    }
  }, [authToken]);

  const login = (token: string, refreshToken: string) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    setAuthToken(token);
    const role = getRoleFromToken(token);
    setUserRole(role);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setAuthToken(null);
    setUserRole("");
    logger.log("Logout finished");
  };

  const hasRole = (role: UserRole) => {
    if (userRole == null) {
      return false;
    }
    return userRole.includes(role);
  };

  return (
    <AuthContext.Provider
      value={{ authToken, userRole, login, logout, hasRole }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
