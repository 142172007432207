import { Outlet } from "react-router-dom";
import React from "react";
import { Box } from "@chakra-ui/react";
export default function SimpleLayout() {
  return (
    <Box>
      <Outlet />
    </Box>
  );
}
