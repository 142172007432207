import React, { FC, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Image,
  HStack,
  Button,
  Link,
  useDisclosure,
  Collapse,
  useToast,
} from "@chakra-ui/react";
import LogoWithText from "./LogoWithText.png";
import "./login.css";
import { apiService } from "../../Services/ApiService";
import { AxiosError } from "axios";
import { logger } from "../../Logger";
import { useAuth } from "../../Services/AuthConext";
import DatePicker from "react-datepicker";

interface LoginProps {}

type RegistrationFormData = {
  email: string;
  password: string;
  name: string;
  surname: string;
  birthday: string;
  address: string;
  city: string;
  zip: string;
  phone: string;
  country: string;
};

type LoginFormData = {
  email: string;
  password: string;
};

const Log = () => {
  return (
    <Box>
      <Image
        w="600px"
        objectFit="cover"
        mx="auto"
        alt="Logo"
        src={LogoWithText}
      />
    </Box>
  );
};

const LoginHeader = () => {
  return (
    <Box mb="8">
      <Heading as="h1" fontWeight="bold" fontSize="4xl">
        Login
      </Heading>
      <Text fontWeight="medium" fontSize="2xl">
        to get started
      </Text>
    </Box>
  );
};

const RegisterHeader = () => {
  return (
    <Box mb="8">
      <Heading as="h1" fontWeight="bold" fontSize="4xl">
        Register
      </Heading>
      <Text fontWeight="medium" fontSize="2xl">
        to get started
      </Text>
    </Box>
  );
};

const SocialLogin = () => {
  return (
    <Box>
      <HStack
        width={"full"}
        mb={"4"}
        alighn-items="center"
        justifyContent="center"
      >
        <Link>
          <img
            width="42px"
            alt="Github sign-in"
            src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg"
          />
        </Link>
        <Link>
          <img
            width="42px"
            alt="Google sign-in"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
          />
        </Link>
        <Link>
          <img
            width="42px"
            alt="Facebook sign-in"
            src="https://upload.wikimedia.org/wikipedia/en/0/04/Facebook_f_logo_%282021%29.svg"
          />
        </Link>
      </HStack>
    </Box>
  );
};

const LoginForm = () => {
  const { login, logout } = useAuth();
  const location = useLocation();

  let navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const [registrationFormData, setRegistrationFormData] =
    useState<RegistrationFormData>({
      email: "",
      password: "",
      name: "",
      surname: "",
      birthday: new Date().toISOString(),
      address: "",
      city: "",
      zip: "",
      phone: "",
      country: "",
    });

  const [loginFormData, setLoginFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  useEffect(() => {
    logout();
  }, [logout]);

  const handleLoginFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginFormData({
      ...loginFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLoginSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    logger.log(`Login data: ${JSON.stringify(loginFormData)}`);
    apiService
      .loginUser(loginFormData)
      .then((response) => {
        toast({
          title: "Login successful.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        const { token, refreshToken } = response.data;
        login(token, refreshToken);

        // Extract the returnUrl from the query params
        const queryParams = new URLSearchParams(location.search);
        const returnUrl = queryParams.get("returnUrl") || "/"; // Default to home page if no returnUrl

        navigate(returnUrl);
      })
      .catch((reason: AxiosError) => {
        logger.error(reason);
        toast({
          title: "Login error",
          description: "Username or password not correct.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleRegistrationFormChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRegistrationFormData({
      ...registrationFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegistrationSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    logger.log("Form Data:", registrationFormData);

    // Replace this with your API call
    apiService
      .registerUser({
        email: registrationFormData.email,
        password: registrationFormData.password,
        profile: {
          email: registrationFormData.email,
          name: registrationFormData.name,
          surname: registrationFormData.surname,
          birthday: registrationFormData.birthday,
          address: registrationFormData.address,
          city: registrationFormData.city,
          zip: registrationFormData.zip,
          phone: registrationFormData.phone,
          country: registrationFormData.country,
        },
      })
      .then((response) => {
        // Reset form and show success message
        setRegistrationFormData({
          email: "",
          password: "",
          name: "",
          surname: "",
          birthday: new Date().toISOString(),
          address: "",
          city: "",
          zip: "",
          phone: "",
          country: "",
        });
        onToggle();
        toast({
          title: "Registration successful.",
          description: "You can now log in with your new account.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        const desc = error.response
          ? error.response.data.description
          : error.message;
        // Show error message
        toast({
          title: "Unable to register",
          description: desc,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Flex alignItems="center">
      <Box>
        <Log />
      </Box>
      <Box
        borderWidth="1"
        borderRadius="16"
        borderColor="#DADADA"
        px="16"
        py="8"
        width="full"
        boxShadow="lg"
        bg="#FAFAFA"
      >
        <Box>
          <Collapse in={isOpen} animateOpacity>
            <Box>
              <LoginHeader />
            </Box>
            <Box>
              <form onSubmit={handleLoginSubmit}>
                <FormControl>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    value={loginFormData.email}
                    placeholder="Enter your email"
                    bg="white"
                    borderColor="#EAEAEA"
                    boxShadow="md"
                    onChange={handleLoginFormChange}
                    isRequired
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mt="4">Password</FormLabel>
                  <Input
                    type="password"
                    name="password"
                    value={loginFormData.password}
                    placeholder="Enter your password"
                    bg="white"
                    borderColor="#EAEAEA"
                    boxShadow="md"
                    onChange={handleLoginFormChange}
                  />
                </FormControl>
                <Button
                  type="submit"
                  width="full"
                  mt="4"
                  bg="brand.50"
                  _hover={{ bg: "brand.100" }}
                  color="white"
                  isLoading={isSubmitting}
                >
                  Log in
                </Button>
              </form>
            </Box>
            <Box textAlign="center" mt="4">
              <Link>Forgot Password?</Link>
            </Box>
          </Collapse>
          <Collapse in={!isOpen} animateOpacity>
            <Box>
              <RegisterHeader />
            </Box>
            <Box>
              <div data-testid="Register">
                <form onSubmit={handleRegistrationSubmit}>
                  <FormControl id="email" mb="4" isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      value={registrationFormData.email}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>

                  <FormControl id="password" mb="4" isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      name="password"
                      value={registrationFormData.password}
                      onChange={handleRegistrationFormChange}
                      minLength={8}
                      maxLength={20}
                      pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$"
                      title="Must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                    />
                  </FormControl>

                  <FormControl id="name" mb="4" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      name="name"
                      value={registrationFormData.name}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>
                  <FormControl id="surename" mb="4" isRequired>
                    <FormLabel>Surname</FormLabel>
                    <Input
                      name="surname"
                      value={registrationFormData.surname}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>
                  <FormControl id="birthday" mb="4" isRequired>
                    <FormLabel>BIRTH DATE</FormLabel>
                    <DatePicker
                      name="brithday"
                      yearDropdownItemNumber={100}
                      showYearDropdown
                      scrollableYearDropdown
                      showIcon
                      selected={
                        registrationFormData.birthday === ""
                          ? new Date()
                          : new Date(registrationFormData.birthday)
                      }
                      onChange={(date) => {
                        setRegistrationFormData({
                          ...registrationFormData,
                          birthday:
                            date !== null
                              ? date?.toISOString()
                              : new Date().toISOString(),
                        });
                        logger.log(
                          `Register dto: ${JSON.stringify(
                            registrationFormData
                          )}`
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl id="address" mb="4" isRequired>
                    <FormLabel>Address</FormLabel>
                    <Input
                      name="address"
                      value={registrationFormData.address}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>
                  <FormControl id="zip" mb="4" isRequired>
                    <FormLabel>Zip</FormLabel>
                    <Input
                      name="zip"
                      value={registrationFormData.zip}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>
                  <FormControl id="city" mb="4" isRequired>
                    <FormLabel>City</FormLabel>
                    <Input
                      name="city"
                      value={registrationFormData.city}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>
                  <FormControl id="country" mb="4" isRequired>
                    <FormLabel>Country</FormLabel>
                    <Input
                      name="country"
                      value={registrationFormData.country}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>
                  <FormControl id="phone" mb="4" isRequired>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      name="phone"
                      value={registrationFormData.phone}
                      onChange={handleRegistrationFormChange}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    bg="brand.50"
                    _hover={{ bg: "brand.100" }}
                    color="white"
                    isLoading={isSubmitting}
                    width="full"
                  >
                    Register
                  </Button>
                </form>
              </div>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <div className="divider">
            <div className="divider-line"></div>
            {/* <div className="divider-text">or</div> */}
            <div className="divider-line"></div>
          </div>
        </Box>
        {/* <Box>
                    <SocialLogin />
                </Box> */}
        <Box textAlign="center">
          {isOpen ? (
            <Text>
              New User? <Link onClick={onToggle}>Register</Link>
            </Text>
          ) : (
            <Text>
              Already user? <Link onClick={onToggle}>Login</Link>
            </Text>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

const Login: FC<LoginProps> = () => (
  <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
    <Box>
      <Box>
        <LoginForm />
      </Box>
    </Box>
  </Flex>
);

export default Login;
